import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import myWeb from "../../Assets/Projects/myWeb.png";
import OTA from "../../Assets/Projects/OTA_SMA.png";
import thesis from "../../Assets/Projects/Thesis.png";
import digitwin from "../../Assets/Projects/DigitalTwin.png";
import sensors from "../../Assets/Projects/virtualSensors.png";
import registry from "../../Assets/Projects/model_registry_3.png";
import semseg from "../../Assets/Projects/SemSeg_2.png";
import bertserini from "../../Assets/Projects/BertSerini.png";
import rotor from "../../Assets/Projects/rotorsDetection.png";
import edgeCloud from "../../Assets/Projects/Edge_Cloud_Collaborative_Inference_2.png";
import wine from "../../Assets/Projects/Wine_Quality_Estimation.png";
import housing from "../../Assets/Projects/boston_housing.png";
import sales from "../../Assets/Projects/sales_forecasting.png";
import Taiwan from "../../Assets/Projects/Taiwan.png";

import semseg_paper from "../../Assets/SemanticSegmentation_ComputerVision.pdf";
import bertserini_paper from "../../Assets/Bertserini_Q&A_NLP.pdf";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>


        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={OTA}
              isBlog={false}
              title="Cloud-Based IoT Device Management and Fleet Monitoring with OTA Smart Adaptor"
              description="The OTA Smart Adaptor project developed a cloud-enabled IoT solution for managing over-the-air (OTA) updates, telemetry, and remote device control. It supported firmware (FOTA) and configuration (COTA) updates, with a telemetry dashboard offering real-time insights and faulty behavior detection. The cloud solution, built on Azure IoT Hub and Functions App, handled secure device communications and data processing at scale. A customer-facing GUI enabled remote pump management, fleet commands, and performance monitoring. Due to an NDA agreement with the company, the source code for this project is not publicly available."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={thesis}
              isBlog={false}
              title="Cloud-Based Data-Driven Predictive Maintenance of eLOP Using Digital Twin Technology"
              description="This project explores a cloud-based predictive maintenance model for electric lubrication oil pumps (eLOP), integrating a hybrid anomaly detection framework and supervised Remaining Useful Life (RUL) prediction. A two-layer recurrent neural network with Deep SVDD identifies anomalies, while RUL is predicted using machine learning models like Support Vector Regression. The solution leverages cloud technologies and a digital twin to provide real-time monitoring, optimizing the maintenance process and reducing equipment failure. The model demonstrates significant potential in enhancing reliability and operational efficiency for eLOPs. This project was my Master's thesis, completed while working at a company."
              demoLink = "https://webthesis.biblio.polito.it/26815/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sensors}
              isBlog={false}
              title="Virtual Sensor Algorithms for Automotive components"
              description="This project involved the development of virtual sensor algorithms designed to replace physical sensors in vehicles' electric lubrication oil pumps. The focus was on three sensor types: oil temperature, flow rate, and pressure. Two deployment models were created: a lightweight embedded model that runs directly on the vehicle's chip without requiring connectivity, and a cloud-based model that processes real-time data through external communication with the vehicle. Both approaches provided accurate, real-time sensor readings, optimizing performance based on the system’s operational needs. Due to an NDA agreement with the company, the source code for this project is not publicly available."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={digitwin}
              isBlog={false}
              title="Digital Twin for eLOP: Cloud-Based Predictive Asset Management"
              description="This project involved the creation of a digital twin for an electric lubrication oil pump (eLOP), allowing for real-time monitoring, simulation, and control of the asset. The solution enabled seamless integration between physical systems and digital models, offering predictive insights through AI-powered analytics. Hosted in the cloud, it provided a collaborative platform for multiple users, utilizing advanced tools such as IoT Hub, Event Hubs, and Cosmos DB. The digital twin supported improved decision-making, predictive maintenance, and operational optimization, driving business value and enhancing product development. Due to an NDA agreement with the company, the source code for this project is not publicly available."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={rotor}
              isBlog={false}
              title="Automatic Detection of Misplaced Rotors in Assembly Line Using Computer Vision"
              description="Developed a computer vision solution to address a recurring issue in a pump manufacturing assembly line, where incorrect rotors were occasionally used, leading to customer returns. The system involved placing a camera in a strategic location on the assembly line, under optimal lighting conditions, to capture real-time images of rotors. A custom-built computer vision model was embedded to detect misplaced rotors in KLT containers. Upon detection of an incorrect rotor, a red light and buzzer alerted operators to address the issue, significantly improving quality control and reducing product returns. Due to company policies, the code and further project details cannot be shared."
              ghLink=""
            />
          </Col>

        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={semseg}
              isBlog={false}
              title="Real-Time Semantic Segmentation with Domain Adaptation for Autonomous Vehicles"
              description="This project focuses on improving real-time semantic segmentation for autonomous vehicles by tackling the challenge of domain shift between synthetic and real-world datasets. Using the Bilateral Segmentation Network (BiSeNet) architecture, the model is trained on synthetic data from the IDDA dataset and adapted to the real-world CamVid dataset through unsupervised adversarial domain adaptation. This approach significantly improves the accuracy of segmenting critical objects like pedestrians and bicycles, which are vital for safe autonomous driving. The use of weighted loss functions further enhances performance, particularly for minority classes that are often underrepresented in training data."
              paperLink = {semseg_paper}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bertserini}
              isBlog={false}
              title="Multilingual End-to-End Open-Domain Question Answering Bot with BERTserini"
              description="This project introduces a novel Pytorch Lightining implementation for enhancing the BERTserini architecture, integrating BERT with Pyserini for efficient end-to-end open-domain question answering. The system retrieves relevant information from large datasets such as Wikipedia, and BERT extracts precise answer spans from the text. Additionally, the project introduces multilingual support through a FastText-BART pipeline for language detection and machine translation, enabling users to interact in various languages. The entire pipeline is deployed via a Telegram bot, offering a seamless chatbot experience for real-time question answering across multiple languages."
              ghLink=""
              paperLink = {bertserini_paper}
            />
          </Col>

          

        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={edgeCloud}
              isBlog={false}
              
              title="Edge-Cloud Collaborative Inference for Keyword Spotting"
              description="This project explores the implementation of a low-latency keyword spotting system using edge-cloud collaborative inference. A TFLite keyword spotting model was trained on audio signals using TensorFlow, optimized for both speed and accuracy. The system comprises two inference pipelines: a Fast pipeline running locally on a Raspberry Pi and a Slow pipeline running on the cloud. A RESTful protocol synchronizes the communication between these pipelines, ensuring real-time responsiveness. The system achieves over 91% accuracy, with a total inference time under 40 ms and communication cost below 2 MB."
              ghLink=""
              demoLink="https://flutterflows.azurewebsites.net"
              
            />
          </Col>

          <Col md={4} className="project-card ">
            <ProjectCard
              imgPath={registry}
              isBlog={false}
              title="Model Registry for Temperature and Humidity Forecasting with Automated Alerts"
              description="Developed a model registry system that hosts multiple TFLite forecasting models (MLP, LSTM, CNN) for temperature and humidity predictions. The system allows users to interact with the registry via a custom-built API, enabling model storage, retrieval, and predictions through HTTP methods. Additionally, an MQTT communication protocol was implemented to send real-time alerts to users when forecasting prediction errors exceed a predefined threshold. This system enhances user interaction with predictive models while providing timely notifications for potential environmental risks."
              ghLink=""
              demoLink=""
            />
          </Col>
          
          <Col md={4} className="project-card ">
            <ProjectCard
              imgPath={Taiwan}
              isBlog={false}
              title="Taiwanese Bankruptcy Prediction"
              description="This project focuses on predicting bankruptcy for Taiwanese companies using advanced machine learning techniques. The model tackles class imbalance by applying SMOTE with TomekLinks resampling, ensuring balanced data for training. A heuristic outlier detection approach was employed using a combination of DBScan clustering and the KNN algorithm. To enhance model efficiency, principal component analysis (PCA) was utilized for dimensionality reduction. The project concluded with the successful training and evaluation of accurate classification models."
              ghLink=""
              demoLink=""
            />
          </Col>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={wine}
              isBlog={false}
              title="Wine Quality Estimation"
              description="This project explores the prediction of wine quality scores using sophisticated machine learning models and advanced feature engineering techniques. Utilizing a dataset of 150,930 wine reviews, the project began with exploratory data analysis (EDA) to uncover insights and trends within the data. A variety of feature encoding methods were employed, including Word2Vec embeddings weighted by TF-IDF to capture semantic relationships in textual wine descriptions, and standard, weighted mean, and label encodings for categorical attributes. The model pipeline integrated multiple regression techniques, such as Random Forest, Linear Regression, and Light Gradient Boosting Machine (LGBM), with comprehensive hyperparameter tuning and cross-validation to optimize performance. The LGBM model delivered the most robust results, achieving superior accuracy by leveraging its ability to handle complex interactions and outliers within the dataset."
              ghLink=""
            />
          </Col>

          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={housing}
              isBlog={false}
              title="Predictive Analysis of Boston Housing Prices"
              description="This project focused on leveraging machine learning models to predict median housing prices in the Boston area. The dataset was meticulously preprocessed, including data cleaning, missing value imputation, and feature scaling. Multiple regression models, including XGBoost, Support Vector Regression, and Ridge Regression, were developed and fine-tuned to maximize predictive performance. The final model achieved an impressive R² score, uncovering critical insights into the factors influencing housing prices in Boston."
              ghLink=""
            />
          </Col>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sales}
              isBlog={false}
              title="Strategic Sales Forecasting with Regression Analysis"
              description="This project focused on developing an advanced sales forecasting model using regression-based machine learning techniques to predict future sales trends. Interactive widgets were designed and implemented to enhance exploratory data analysis (EDA), providing a dynamic and user-friendly experience. External data integration was achieved using Google Cloud’s BigQuery, with tailored queries executed in a Jupyter Notebook environment. This enriched the forecasting model, leading to more accurate predictions and deeper project insights."
              ghLink=""
            />
          </Col>
          
          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
