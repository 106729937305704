import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Particle from "../Particle";
import emailjs from 'emailjs-com'; // Import EmailJS
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  // Validation for Name: No numbers or special characters allowed
  const validateName = (name) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    return nameRegex.test(name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate Name
    if (!validateName(formData.name)) {
      alert("Please enter a valid name. Numbers or special characters are not allowed.");
      return;
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Ensure message is not empty
    if (formData.message.trim() === "") {
      alert("Please enter a message.");
      return;
    }

    // Prepare the data to send using EmailJS
    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
      phone: formData.phone,
    };

    emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      ).then((result) => {
        alert('Message sent successfully!');
        // Reset the form after successful submission
        setFormData({
          name: "",
          email: "",
          message: "",
          phone: "",
        });
      }, (error) => {
        alert('Failed to send message. Please try again later.');
        console.error('EmailJS error:', error.text);
      });
    };
      
  

  return (
    <Container fluid className="contact-section">
      <Particle />
      <Container>
        <Row>
          <Col md={12} className="text-center d-flex flex-column align-items-center contact-header">
            <h1 className="heading2">Get In Touch</h1>
            <p className="subheading">Feel free to drop a message below</p>
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 6, offset: 3 }} className="contact-form">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Name <span style={{ color: 'red' }}>*</span> {/* Red asterisk to indicate required */}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  maxLength={100}
                />
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label>Email <span style={{ color: 'red' }}>*</span> {/* Red asterisk to indicate required */}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  maxLength={254}
                />
              </Form.Group>

              <Form.Group controlId="formPhone">
                <Form.Label>Phone Number</Form.Label>
                <PhoneInput
                  country={'us'} // Default country
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  inputProps={{
                    name: 'phone',
                    required: false,
                    autoFocus: false,
                    placeholder: "Enter your phone number",
                  }}
                  countryCodeEditable={true} // Allows editing the country code
                />
              </Form.Group>

              <Form.Group controlId="formMessage">
                <Form.Label>Message <span style={{ color: 'red' }}>*</span> {/* Red asterisk to indicate required */}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Write your message here"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  maxLength={1500}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Contact;
