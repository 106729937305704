import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="home-about-body" style={{padding: "0"}}>
          <p>
            <span className="purple">Karim Dandachi </span>
            is the founder of <span className="purple"> AccelerAI SAL.,</span>
            <br />
            <p>
            an Offshore company specializing in delivering tailored AI solutions to businesses across various industries.<br /><br />
            With extensive experience leading cross-functional teams in top companies like Bosch, Karim has built a strong reputation for creating AI-driven innovations and applies cutting-edge knowledge to drive strategic transformation for organizations, solving complex, real-world problems.<br />
            <br />
            <br />
            <b>
              <b className="purple">Key Expertise:</b>
            </b>
            <ul>
            <li className="about-activity">
              <ImPointRight /> <b>AI-Driven Business Solutions: </b> Crafting tailored AI solutions to address real-world business challenges.
            </li>
            <li className="about-activity">
              <ImPointRight /> <b>Leadership:</b> Leading cross-functional teams to drive innovation and collaboration across organizations.
            </li>
            <li className="about-activity">
              <ImPointRight /> <b>Strategic Transformation:</b> Empowering organizations to leverage AI for scalable growth and operational efficiency.
            </li>
          </ul>
          <br />
            As a visionary AI leader, Karim is committed to advancing the possibilities of artificial intelligence, ensuring that businesses stay ahead in an ever-evolving digital landscape.
            </p>
            
            
          </p>
          
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
