import React, { useState, useEffect } from 'react';
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  DiReact,
  DiDocker as DiDockerIcon, // Aliased to avoid conflict with SiDocker
  DiMongodb,
  DiGit,
} from "react-icons/di";
import {
  SiPython,
  SiJavascript,
  SiCsharp,
  SiArduino,
  SiRaspberrypi,

  SiTensorflow,
  SiPytorch,
  SiScikitlearn,
  SiPandas,
  SiNumpy,
  SiSpacy,
  SiHuggingface,

  SiPlotly,
  SiTableau,

  SiMicrosoftazure,
  SiNvidia,
  SiDocker,

  SiMysql,
  SiSqlite,
  SiSqlalchemy,

  SiApachespark,
  SiApachehadoop,

  SiScrapy,
} from "react-icons/si";
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure you're importing Bootstrap CSS

function Techstack() {
  // Function to render tooltip
  const renderTooltip = (props, text) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  // Array of objects for each icon and its label
  const techs = [
    // Languages
    { icon: <SiPython />, label: "Python" },
    { icon: <SiJavascript/>, label: "Javascript" },
    { icon: <SiCsharp />, label: "C#" },
    { icon: <SiArduino />, label: "Arduino" },
    { icon: <SiRaspberrypi />, label: "Raspberry pi" },  

    // ML&AI Frameworks
    { icon: <SiTensorflow />, label: "Tensorflow" },
    { icon: <SiPytorch />, label: "PyTorch" },
    { icon: <SiScikitlearn />, label: "Scikit-Learn" },   
    { icon: <SiPandas />, label: "Pandas" },
    { icon: <SiNumpy />, label: "Numpy" },
    { icon: <SiSpacy />, label: "spaCy" },
    { icon: <SiHuggingface />, label: "Hugging Face" },

    // Data Science and Visualization
    { icon: <SiPlotly />, label: "Plotly" },
    { icon: <SiTableau />, label: "Tableau" },

    // Cloud & DevOps
    { icon: <SiMicrosoftazure />, label: "Microsoft Azure" },
    { icon: <DiDockerIcon />, label: "Docker" },

    // Databases
    { icon: <SiSqlite  />, label: "SqlLite" },
    { icon: <SiSqlalchemy  />, label: "SQL Alchemy" },
    { icon: <SiMysql  />, label: "MySQL" },
    { icon: <DiMongodb />, label: "MongoDB" },

    // Big Data & Distributed Computing
    { icon: <SiApachespark />, label: "PySpark" },
    { icon: <SiApachehadoop />, label: "Hadoop" },
    // Frontend/Backend Frameworks:
    { icon: <DiReact />, label: "React" },

    // Version Control
    { icon: <DiGit />, label: "Git" },

    // Web Scraping
    { icon: <SiScrapy />, label: "Scrapy" },
    
    // ... Add more techs as needed ...
  ];

  // const TechGrid = () => {
  //   // State to control tooltip visibility
  //   const [showTooltip, setShowTooltip] = useState(false);
  
  //   // useEffect to ensure tooltips are shown when the component is mounted
  //   useEffect(() => {
  //     setShowTooltip(true); // Set tooltips to always show when the component is mounted
  //   }, []); // This effect runs once, when the component mounts
  
  //   return (
  //     <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
  //       {techs.map((tech, index) => (
  //         <Col xs={4} md={2} className="tech-icons text-center" key={index}>
  //           <OverlayTrigger
  //             placement="top"
  //             show={showTooltip}  // Ensure the tooltip is always visible
  //             overlay={(props) => renderTooltip(props, tech.label)}
  //           >
  //             <div>{tech.icon}</div>
  //           </OverlayTrigger>
  //         </Col>
  //       ))}
  //     </Row>
  //   );
  // }
  
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {techs.map((tech, index) => (
        <Col xs={4} md={2} className="tech-icons" key={index}>
          
          <div className="tech-icon-wrapper">
            {tech.icon}
            {/* Manually render the tooltip above the icon */}
            <div className="tech-tooltip">
              {tech.label}
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default Techstack;
