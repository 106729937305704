import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './skillstack.css'; // Make sure this points to your actual CSS file

function SkillCarousel() {
  const skills = [
    {
      name: 'Jalal Khan - Engineering & Innovations Coordinator @VHIT',
      description: '"Karim is a highly skilled data scientist with good knowledge of cloud infrastructures and excellent time management capabilities. He is an engineer on whom I have been able to rely on and I have no doubt that he will continue to excel in any future role he undertakes. I wholeheartedly recommend Karim and I am confident that he will be an asset to any organization."'
    },

    {
      name: 'Antonio Tavera - CEO & Founder @Focoos AI',
      description: '"I got to know Karim while supervising a computer vision project as a Teaching Assistant for the Deep Learning course at the Politecnico di Torino. Karim demonstrated consistent dedication and a strong aptitude for teamwork, making a significant contribution to the project\'s success. His final presentation, meticulously prepared, highlighted both his solid technical skills and his excellent communication and synthesis abilities."'
    },
    
    {
      name: 'Richie Ojo - Senior IT Asset Manager @Zurich Insurance ',
      description: '"Karim completed a project for me and he was fantastic, he was open to communication to understand what I need and how I\'d like it to be done. His output was great and his attitude is exceptional. If I have any other projects in the future I\'ll consider Karim first."'
    },

    {
      name: 'Francesco Borri - Senior ML Engineer @Coca-Cola',
      description: '"I had the pleasure of working with Karim during a Data Science Hackathon organized by Bosch\'s AI department. Karim demonstrated exceptional teamwork and expertise as a data scientist, which were crucial given the fast-paced, time-sensitive nature of hackathons."'
    },

    {
      name: 'Mirakram Aglarov - Head of AI R&D Center @BHOS | Senior ML Engineer @PRODATA',
      description: '"I have known Karim since our time together during our master\'s degree at PoliTo. As a teammate, his exceptional technical and interpersonal skills were invaluable to our group projects. His insights on various topics demonstrated not only his strong research abilities but also his passion for innovation, which aligns perfectly with the demands of both the present and future."',
    },

    {
      name: 'Silva Bashllari - Co-Manager @DAIT',
      description: '"I met Karim during my studies at Politecnico di Torino. I can definitely say that Karim has a talent for diving into chunks of information and selecting the most useful bits. He proved to be a rigorous and reliable member in group projects. Last but not least, he is definitely the kind of person you can count on." ',

    },

    /*{
      name: 'Natasha El Khatib - Automotive Cybersecurity Leader | Writer',
      description: '',
    },

    {
      name: 'Barbara Caputo - Director of AI - Polito | Board Member @ELLIS | Head of VANDAL Laboratory',
      description: '',
    },
    
    
    
    

    
    
   
      {
        name: 'Saad Mohamad - ML/AI Lead @Cellvoyant',
        description: ''
      },
      {
        name: 'Farzad Imanpoor - ',
        description: ''
      },
      */
      
    // ... Add more skills with descriptions as needed ...
  ];

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true, // Center the current slide
    centerPadding: '60px', // Padding on the sides when centerMode is true
    slidesToShow: 3, // Show three slides at a time
    slidesToScroll: 1,
    focusOnSelect: true, // Focus on the slide clicked
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ padding: '20px'}}>
      <Slider {...settings}>
        {skills.map((skill, index) => (
          <div key={index} className="skill-block">
            <h3>{skill.name}</h3>
            <p>{skill.description}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SkillCarousel;
