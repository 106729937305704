import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <div style={{ position: "relative", zIndex: 10 }}>
      <Typewriter
        options={{
          strings: [
            "Founder & CEO : AccelerAI SAL. (Offshore)",
            "Data Scientist | AI Engineer",
            "Specializing in delivering Scalable AI Solutions",
          ],
          autoStart: true,
          loop: true,
          deleteSpeed: 50,
          delay: 20,
        }}
      />
    </div>
  );
}

export default Type;
