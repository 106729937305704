import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import laptopImg from "../../Assets/about.png";
import { MdEmail } from "react-icons/md";

import { Link } from "react-router-dom";

import { AiFillGithub,AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { Instagram } from "@material-ui/icons";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1
              style={{
                fontSize: "2.6em",
                fontWeight: "bold",
                textShadow: "2px 2px #000000",
              }}
            >
              <span className="purple">Welcome to My Digital Space</span>
            </h1>
            <p className="home-about-body">
            Here, I combine my expertise in AI and data science to bring innovative solutions to complex problems.<br /> Explore:
            {" "}<br />
            <ul>
            <li><strong><Link className="purple" to="/about">
                            About me:
                          </Link></strong> Discover who I am, what I do in AI and data science, read testimonials from industry experts, and explore the programming languages and frameworks I specialize in.</li>
            <li><strong><Link className="purple" to="/project">
                            Projects:
                          </Link></strong> Dive into my latest AI-driven projects and see how they’re impacting various industries.</li>
            <li><strong><Link className="purple" to="/contact">
                            Collaborate:
                          </Link></strong> Interested in working together? Let's discuss how we can bring AI solutions to your business.</li>
            </ul>
            
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={laptopImg} alt="about" className="img-fluid" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h4 className="purple">Feel free to connect with me</h4>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/KarimDandachi/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/karim-dandachi/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <Link 
                  to="/contact" 
                  className="icon-colour home-social-icons"
                >
                  <MdEmail />
                </Link>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/kd_99/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <Instagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
